
import {computed, defineComponent, onMounted, onUpdated, ref} from "vue";
import AddCollection from "@/components/general-modals/collection/AddCollection.vue";
import AddCollectionWithInvoices from "@/components/general-modals/collection/AddCollectionWithInvoices.vue";
import AddCollectionWithManuel from "@/components/general-modals/collection/AddCollectionWithManuel.vue";
import PaymentHistory from "@/components/general-modals/collection/PaymentHistory.vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {libTools} from "@/core/appcore/libs/Lib_Tools";
import {Field} from "vee-validate";
import paymentMethods from "@/core/data/paymentMethods";
import {dateConvert} from "@/core/filters/datatime";
import {Turkish} from "flatpickr/dist/l10n/tr";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';


interface srcItem {
  srcFromDate: string;
  srcToDate: string;
  srcStartDate1: string;
  srcStartDate2: string;
  srcFinishDate1: string;
  srcFinishDate2: string;
  srcAccount: string;
}

export default defineComponent({
  name: "non-payment-list",
  components: {
    Field,
    AddCollection,
    AddCollectionWithInvoices,
    AddCollectionWithManuel,
    PaymentHistory,
  },

  data: function () {
    return {
      imgUrl: '',
      searchName: '',
      sortField: '',
      sortType: '',
      filterField: ['Title'],
      filterFieldName: ['Voucher No','Cari Hesap'],
    }
  },

  computed: {

    filteredTableList: function () {
      if (this.arrNonPaymentList !== undefined){
        return this.arrNonPaymentList.filter(filteredList =>
            (filteredList.AccountTitle !== undefined && !(filteredList.AccountTitle).toLowerCase().indexOf(this.searchName.toLowerCase()))||
            (filteredList.VoucherNo !== undefined && !(filteredList.VoucherNo).toLowerCase().indexOf(this.searchName.toLowerCase()))

        );
      }else{
        return [];
      }
    },



  },

  setup() {

    onMounted(()=>{
      setDatePicker();
      store.dispatch(Actions.ACCOUNT_LIST_ALL, {});
    });

    onUpdated(()=>{
      getDeptType(arrNonPaymentList.value);

    });



    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const arrNonPaymentList = ref([]);
    const arrCheckedReservations = ref([]);
    const indexCheckedReservations = ref(0);
    const refComp_AddPayment = ref(null);
    const myAccountList = computed(() => {
      return store.getters.accountList;
    });



    const srcItem = ref<srcItem>({
      srcFromDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcToDate: dateConvert.method.dateToflatpicker(dateConvert.method.getTodayYMD()),
      srcStartDate1: "",
      srcStartDate2: "",
      srcFinishDate1: "",
      srcFinishDate2: "",
      srcAccount: "",
    });

    var fpickStartDate1;
    var fpickStartDate2;
    var fpickFinishDate1;
    var fpickFinishDate2;

    function setDatePicker() {
      let tmpSrcFromDate = flatpickr("#srcFromDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
      let tmpSrcToDate = flatpickr("#srcToDate", {"locale": Turkish, dateFormat: "d-m-Y (D)", defaultDate: 'today',});
      fpickStartDate1 = flatpickr("#scr_ReservationsList_StartDate1", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
      fpickStartDate2 = flatpickr("#scr_ReservationsList_StartDate2", {"locale": Turkish, dateFormat: "d-m-Y (D)", });

      fpickFinishDate1 = flatpickr("#scr_ReservationsList_FinishDate1", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
      fpickFinishDate2 = flatpickr("#scr_ReservationsList_FinishDate2", {"locale": Turkish, dateFormat: "d-m-Y (D)", });
    }



    function clickCheckbox(prmResID) {
      if ( isAllSame() === false){
        const checkboxid = document.getElementById("inputNonPaymentReservation_"+prmResID) as HTMLInputElement;
        checkboxid.checked = false;

      }
      getCheckedIDs();
    }

    function sendSingleResPayment(PrmID, PrmRemainingAmount, PrmStartDate, PrmFinishDate, prmVoucherNo) {
      arrCheckedReservations.value=[];
      let objSelected = {
        ReservationID:PrmID,
        Amount:0,
        RemainingAmount:Number(PrmRemainingAmount),
        StartDate:PrmStartDate,
        FinishDate:PrmFinishDate,
        VoucherNo:prmVoucherNo
      }
      arrCheckedReservations.value.push(objSelected);
      refreshPaymentItems();
    }


    function getCheckedIDs(){
      const vvClassName = "inputNonPaymentReservation";
      arrCheckedReservations.value=[];
      indexCheckedReservations.value=0;
      const arrElements = document.getElementsByClassName(vvClassName);
      for (let i=0; i<arrElements.length; i++){
        let eachElemnt = arrElements[i] as HTMLInputElement;

        //console.log(eachElemnt.getAttribute("reservationID") + " " + eachElemnt.checked + " " + eachElemnt.getAttribute("accountID"));
        if (eachElemnt.checked) {
          let objChecked = {
            ReservationID:eachElemnt.getAttribute("reservationID"),
            Amount:0,
            RemainingAmount:Number(eachElemnt.getAttribute("RemainingAmount")),
            StartDate:eachElemnt.getAttribute("resStartDate"),
            FinishDate:eachElemnt.getAttribute("resFinishDate"),
            VoucherNo:eachElemnt.getAttribute("voucherNo")
          }
          arrCheckedReservations.value.push(objChecked);

          //console.log("TrakingList getCheckedIDs " + JSON.stringify(arrCheckedReservations.value));
        }
        if(arrCheckedReservations.value.length>1){
          (document.getElementById("btnBulkCollection") as HTMLElement).style.setProperty("display", "block", "important");
        }else {
          (document.getElementById("btnBulkCollection") as HTMLElement).style.setProperty("display", "none", "important");

        }
      }

      //console.log("ArrCheckedReservations Value " + JSON.stringify(arrCheckedReservations.value));

      arrCheckedReservations.value.sort((a, b) => {
        if (a.StartDate > b.StartDate) {
          return 1
        } else if (a.StartDate < b.StartDate) {
          return -1
        } else {
          return 0
        }
      })
      refreshPaymentItems();

      return arrCheckedReservations.value;
    }


    function refreshPaymentItems() {
      (refComp_AddPayment.value as any).showComp();

    }


    function isAllSame(){
      const vvClassName = "inputNonPaymentReservation";
      arrCheckedReservations.value=[];
      const objPrevius = {};
      const arrElements = document.getElementsByClassName(vvClassName);
      for (let i=0; i<arrElements.length; i++){
        let eachElemnt = arrElements[i] as HTMLInputElement;


        if (eachElemnt.checked) {
          //console.log( eachElemnt.checked + " Each AccountID " + eachElemnt.getAttribute("accountID") + " Each reservationID " + eachElemnt.getAttribute("reservationID") + " " +  (objPrevius as any).reservationID);
          if((objPrevius as any).accountID !== undefined){
            if((objPrevius as any).accountID !== eachElemnt.getAttribute("accountID")){

              //console.log( " Eşit değil AccountIDs " + eachElemnt.getAttribute("accountID") + " Each reservationID " + eachElemnt.getAttribute("reservationID") + " Öceki Account ID " + (objPrevius as any).accountID + " Önceki REs ID " + (objPrevius as any).reservationID);
              return false;
            }
          }
          (objPrevius as any).accountID = eachElemnt.getAttribute("accountID");
          (objPrevius as any).reservationID = eachElemnt.getAttribute("reservationID");
        }
      }

      return true;
    }

    function getDeptType(prmData) {
      if(prmData!==null && prmData!==undefined){
        for (let i=0; i<prmData.length; i++){
          let each = prmData[i];
          const myDeptType = document.getElementById("divDeptType"+each.ID);
          const today = dateConvert.method.getTodayYMD();
          const deptDate = each.DeptDate;
          const theDaysBefore3 = dateConvert.method.addDayToDateYMD(deptDate, -2);
          const theDaysBefore2 = dateConvert.method.addDayToDateYMD(deptDate, -1);

          if (each.InDept==="1"){
            if(myDeptType!==undefined && myDeptType!==null){
              //console.log("today deptDate " + today + " " + deptDate)
              myDeptType.style.setProperty("background-color", "#FFC700", "important");
              myDeptType.style.setProperty("color", "white", "important");
              if(today===theDaysBefore3 || today===theDaysBefore2 || deptDate<=today){
                myDeptType.style.setProperty("background-color", "#F1416C", "important");
                myDeptType.style.setProperty("color", "white", "important");
              }
            }

          }


        }
      }

    }

    function click_Search(){
      if (!submitButtonRef.value) {
        return;
      }
      const payload1 = {
        ProcessDateStart: srcItem.value.srcFromDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcFromDate) : "",
        ProcessDateFinish: srcItem.value.srcToDate ? dateConvert.method.flatpickerToDateYM(srcItem.value.srcToDate) : "",
        StartDate1: libTools.method.getSelectedDateFromFlatPicker(fpickStartDate1, 0),
        StartDate2: libTools.method.getSelectedDateFromFlatPicker(fpickStartDate2, 0),
        FinishDate1: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDate1, 0),
        FinishDate2: libTools.method.getSelectedDateFromFlatPicker(fpickFinishDate2, 0),
        AccountID: srcItem.value.srcAccount,
      };
      store.dispatch(Actions.RESERVATIONS_NONPAYMENT_LIST, payload1).then((prmData) => {
        arrNonPaymentList.value = prmData.data;
        submitButtonRef.value.disabled = false;
        submitButtonRef.value.setAttribute("data-kt-indicator", "off");

      }).catch((prmData) => {
        libTools.method.swalError("Tamam", "Hata Oluştu");
      });
      submitButtonRef.value.disabled = false;
      submitButtonRef.value.setAttribute("data-kt-indicator", "on");
      //console.log(submitButtonRef.value);


    }





    const reservationID = ref<number>(0);
    function setItems(ID) {
      reservationID.value=ID;
    }


    return {
      libTools,
      paymentMethods,
      dateConvert,
      click_Search,
      clickCheckbox,
      getCheckedIDs,
      arrNonPaymentList,
      submitButtonRef,
      srcItem,
      myAccountList,
      arrCheckedReservations,
      sendSingleResPayment,
      setItems,
      reservationID,
      refComp_AddPayment,
    };
  },
});

